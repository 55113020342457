import React from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Button, Label } from "reactstrap";

function LoginUI() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoggedin, setLoggedin] = React.useState(false);
  const Navigate = useNavigate();
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const loginHandler = () => {
    // ev.preventDefault();
    if (!username || !password) {
      alert("Enter a Email and Password");
      return;
    }
    if (!isValidEmail(username)) {
      alert("Enter a Valid Email Address");
      return;
    }
    // console.log(username+password);
    // email: "eve.holt@reqres.in",
    // password: "cityslicka"
    fetch(
      "https://tdcp.gop.pk/patriata_api/get_login?email=" +
        username +
        "&pass=" +
        password
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("RESPONSE from login success ", data.data.email);
        if (data.data.length > 0) {
          const fil = data.data.find((u) => u.roles);
          console.log(fil.roles);
          sessionStorage.setItem("Route", fil.service);
          sessionStorage.setItem("City", fil.city);
          sessionStorage.setItem("auth", username);
          Navigate("/starter");
          window.location.reload();
        } else {
          alert("Invalid Detail");
        }
        // setLoggedin(true);
      });

    // console.log(username, password);
  };

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-danger text-white my-4 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "400px" }}
          >
            <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
              <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
              <p className="text-white-50 mb-2">
                Please enter your login and password!
              </p>
              <Label className="mb-4 mx-5 w-100">Email</Label>
              <MDBInput
                wrapperClass="mb-4 mx-5 w-100"
                labelClass="text-white"
                onChange={(ev) => setUsername(ev.currentTarget.value)}
                id="formControlLg"
                type="email"
                size="lg"
              />
              <Label className="mb-4 mx-5 w-100">Password</Label>
              <MDBInput
                onChange={(ev) => setPassword(ev.currentTarget.value)}
                wrapperClass="mb-4 mx-5 w-100"
                labelClass="text-white"
                id="formControlLg"
                type="password"
                size="lg"
              />

              {/* <p className="small mb-3 pb-lg-2">
                <a class="text-white-50" href="#!">
                  Forgot password?
                </a>
              </p> */}
              <Button
                outline
                className="mx-2 px-5"
                color="primary"
                size="lg"
                onClick={loginHandler}
              >
                Login
              </Button>
            </MDBCardBody>

            <span class="text-muted ms-5" href="#!">
              Copyright © Admin Board TDCP 2023
            </span>
          </MDBCard>
          <p className="text-center">
            <span class="text-muted">
              Made by{" "}
              <a href="https://stashtechnologies.com/" class="link-info">
                Stash Technologies
              </a>{" "}
              for a better Web{" "}
            </span>
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default LoginUI;

import { Button, Nav, NavItem } from "reactstrap";
import {
  Link,
  NavLink,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState } from "react";
const navigation = [
  {
    title: "Sight Seeing",
    // href: "/starter",
    icon: "bi bi-bus-front-fill",
    subdivision: [
      {
        title: "Dashboard",
        href: "/starter",
        icon: "bi bi-speedometer2",
      },
      {
        title: "City",
        href: "/city",
        icon: "bi bi-c-circle-fill",
      },
      // {
      //   title: "Alert",
      //   href: "/alerts",
      //   icon: "bi bi-bell",
      // },
      // {
      //   title: "Badges",
      //   href: "/badges",
      //   icon: "bi bi-patch-check",
      // },
      // {
      //   title: "Buttons",
      //   href: "/buttons",
      //   icon: "bi bi-hdd-stack",
      // },
      // {
      //   title: "Cards",
      //   href: "/cards",
      //   icon: "bi bi-card-text",
      // },
      // {
      //   title: "Grid",
      //   href: "/grid",
      //   icon: "bi bi-columns",
      // },
      {
        title: "Add Tour",
        href: "/forms",
        icon: "bi bi-bus-front",
      },
      {
        title: "All Tours",
        href: "/table",
        icon: "bi bi bi-card-list",
      },
      {
        title: "View Booking",
        href: "/booking",
        icon: "bi bi-layout-split",
      },
      {
        title: "Create Booking",
        href: "/create",
        icon: "bi bi bi-building-add",
      },
    ],
  },
  {
    title: "Patriata ChairLift",
    // href: "/starter",
    icon: "bi bi-ethernet",
    subdivision: [
      {
        title: "Dashboard",
        href: "/starter",
        icon: "bi bi-speedometer2",
      },
      {
        title: "City",
        href: "/pcity",
        icon: "bi bi-c-circle-fill",
      },
      {
        title: "Add Facility",
        href: "/pform",
        icon: "bi bi-bus-front",
      },
      {
        title: "All Facility",
        href: "/ptour",
        icon: "bi bi bi-card-list",
      },
      {
        title: "All Reservations",
        href: "/pbooking",
        icon: "bi bi-layout-split",
      },
      {
        title: "New Reservation",
        href: "/pcreate",
        icon: "bi bi bi-building-add",
      },
    ],
  },
  {
    title: "Boating Service",
    // href: "/starter",
    icon: "bi bi-tsunami",
    subdivision: [
      {
        title: "Dashboard",
        href: "/starter",
        icon: "bi bi-speedometer2",
      },
      {
        title: "City",
        href: "/bcity",
        icon: "bi bi-c-circle-fill",
      },
      {
        title: "Add Service",
        href: "/bforms",
        icon: "bi bi-bus-front",
      },
      {
        title: "All Services",
        href: "/btable",
        icon: "bi bi bi-card-list",
      },
      {
        title: "All Reservations",
        href: "/bbooking",
        icon: "bi bi-layout-split",
      },
      {
        title: "New Reservation",
        href: "/bcreate",
        icon: "bi bi bi-building-add",
      },
    ],
  },
  //  {
  //   title: "City",
  //   href: "/city",
  //   icon: "bi bi-c-circle-fill",
  // },
  // {
  //   title: "Add Tour",
  //   href: "/forms",
  //   icon: "bi bi-bus-front",
  // },
  // {
  //   title: "All Tours",
  //   href: "/table",
  //   icon: "bi bi bi-card-list",
  // },
  // {
  //   title: "View Booking",
  //   href: "/booking",
  //   icon: "bi bi-layout-split",
  // },
  // {
  //   title: "Create Booking",
  //   href: "/create",
  //   icon: "bi bi bi-building-add",
  // },
];

const Sidebar = () => {
  const [activeNavItem, setActiveNavItem] = useState(null);

  const toggleActiveNavItem = (title) => {
    setActiveNavItem(title === activeNavItem ? null : title);
  };
  // const toggleActiveNavItem = (title) => {
  //   setActiveNavItem((prevActiveNavItem) => {
  //     return prevActiveNavItem === title ? prevActiveNavItem : title;
  //   });
  // };

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  let location = useLocation();
  const Route = sessionStorage.getItem("Route");
  const FilterNavigation = navigation.filter((n) => {
    if (Route == "All") {
      return n;
    } else if (Route == n.title) {
      return n.title === n.title;
    }
  });
  console.log(FilterNavigation);

  return (
    <div style={{ backgroundColor: "#de0021" }}>
      <div className="d-flex">
        <Button
          color="white"
          className="ms-auto text-white d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-x"></i>
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {FilterNavigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              <a
                href={navi.href}
                className={
                  location.pathname === navi.href
                    ? "active nav-link py-3"
                    : "nav-link py-3"
                }
                onClick={() => {
                  toggleActiveNavItem(navi.title);
                }}
              >
                <i className={navi.icon}></i>
                <span className="ms-3 d-inline-block">{navi.title}</span>
              </a>

              {navi.subdivision && navi.title === activeNavItem && (
                <Nav vertical className="sidebarNav">
                  {navi.subdivision.map((subNavItem, subIndex) => (
                    <NavItem key={subIndex} className="sidenav-bg">
                      <a
                        href={subNavItem.href}
                        className={
                          location.pathname === subNavItem.href
                            ? "active nav-link py-3"
                            : "nav-link py-3"
                        }
                      >
                        <i className={subNavItem.icon}></i>
                        <span className="ms-3 d-inline-block">
                          {subNavItem.title}
                        </span>
                      </a>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;

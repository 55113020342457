import { Route, Routes, useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import Starter from "./views/Starter";
import City from "./views/ui/City";
import FullLayout from "./layouts/FullLayout";

const App = () => {
   const routing = useRoutes(Themeroutes);

  return <div className="dark">
    
        {routing}

    {/* {routing} */}

    </div>;
};

export default App;
